import { createApp, h as createElement } from 'vue';
import BModal from 'buefy/src/components/modal/Modal.vue';
import { getComponentFromVNode } from 'buefy/src/utils/helpers';
import SplashScreen from './SplashScreen.vue';

function SplashScreenModal(modalProps) {
  const container = document.createElement('div');

  // I could not figure out how to extend an existing app to create a new
  // Vue instance on Vue 3. - kikuomax
  const vueInstance = createApp({
    data() {
      return {
        modalVNode: null,
      };
    },

    methods: {
      close() {
        const modal = getComponentFromVNode(this.modalVNode);
        if (modal) {
          modal.close();
        }
      },
    },

    render() {
      const SplashScreenVNode = createElement(
        SplashScreen,
      );

      this.modalVNode = createElement(
        BModal,
        {
          programmatic: true,
          canCancel: false,
          fullScreen: true,
          customClass: 'is-splash-screen',

          onAfterEnter: () => {
            if (modalProps && modalProps.onAfterEnter) {
              const modal = getComponentFromVNode(this.modalVNode);
              modalProps.onAfterEnter(modal);
            }
          },

          onClose: () => {
            vueInstance.unmount();
          },

        },
        {
          default: () => SplashScreenVNode,
        },
      );
      return this.modalVNode;
    },
  });

  return vueInstance.mount(container);
}

export default SplashScreenModal;
