import {
  DialogProgrammatic as Dialog,
  ModalProgrammatic as Modal,
  SnackbarProgrammatic as Snackbar,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '^@/lib/programmatics';

const transformCallBacks = (opts: DialogOptions) => {
  const obj = {
    ...opts,
    onConfirm: opts.confirmCallback || opts.onConfirm || null,
    onCancel: opts.cancelCallback || opts.onCancel || null,
  };
  return obj;
};

export function displayModal(opts: ModalOptions): void {
  Modal.open(transformCallBacks(opts));
}

export function displayDialogPrompt(opts: DialogOptions): void {
  Dialog.prompt(transformCallBacks(opts));
}

export function displayDialog(opts: DialogOptions): void {
  Dialog.confirm(transformCallBacks(opts));
}

export function displaySnackbar(opts: SnackbarOptions): void {
  Snackbar.open(transformCallBacks(opts));
}

export default {
  displayModal,
  displayDialogPrompt,
  displayDialog,
  displaySnackbar,
};
