<template>
  <div class="splash-screen-wrapper">
    <figure class="image is-4by1">
      <img src="@/assets/img/logo-full-white.svg" alt="Traliant Logo" />
    </figure>
    <div class="loader-line"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables/globals.scss';

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.splash-screen-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 30vw;
  width: 192px;
}

.loader-line {
  background-color: white;
  height: 3px;
  margin: 1rem auto;
  overflow: hidden;
  position: relative;
  width: 200px;

  &::before {
    animation: lineAnim 1s linear infinite;
    background-color: $traliant-blue;
    content: "";
    height: 3px;
    left: -50%;
    width: 40%;
    position: absolute;
  }
}
</style>
