import { createApp } from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';

import $api from '@/services/api';
import '@/assets/scss/entry.scss';

const app = createApp(App);
app.config.globalProperties.$api = $api;

app.use(store);
app.use(router);
app.mount('#app-wrap');
