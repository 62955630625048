import axios from 'axios';

import {
  displayDialog,
} from '@/mixins/notifications';

/**
 * Determine if response indicates a session timeout.
 */
const isSessionTimeout = (response: APIResponse): boolean => {
  if (
    response.status === 403
    && typeof response?.data?.error === 'object'
    && response?.data?.error?.message?.toLocaleLowerCase().includes('unauthenticated')
  ) {
    // User was logged in, but now unauthenticated. Probably session timeout.
    return true;
  }

  return false;
};

/**
 * Show session timeout alert modal then refresh or redirect to login URL.
 */
const showTimeoutAlert = () => {
  displayDialog({
    canCancel: false,
    title: 'Session Timeout',
    message: 'Your session has timed out due to inactivity. Click OK to log in again.',
    confirmCallback: () => {
      // eslint-disable-next-line no-use-before-define
      if ($api.defaults.sessionTimeoutRedirect) {
        // eslint-disable-next-line no-use-before-define
        window.location.href = $api.defaults.sessionTimeoutRedirect;
      } else {
        window.location.reload();
      }
    },
  });
};

const $api = axios.create(
  {
    withCredentials: true,
    baseURL: `${process.env.VUE_APP_API_URL}/api/v3/`,
    headers: {},
    timeout: 20000,
    timeoutErrorMessage: 'timeout',
    sessionTimeoutAlertShown: false,
    sessionTimeoutRedirect: null,
    sessionTimeoutDetection: false,
  },
);

$api.interceptors.response.use(
  (response: APIResponse) => response,
  (error: AxiosError) => {
    const { response } = error;
    const { status } = response;

    if (!response) {
      return Promise.reject(error);
    }

    if (error.code === 'ECONNABORTED') {
      // do something with timeouts here
      return Promise.reject(error);
    }

    if (status >= 400) {
      if ($api.defaults.sessionTimeoutDetection && isSessionTimeout(response)) {
        if (!$api.defaults.sessionTimeoutAlertShown) {
          showTimeoutAlert();
          $api.defaults.sessionTimeoutAlertShown = true;
        }
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

export default $api;
