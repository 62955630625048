import { createStore } from 'vuex';
import $api from '@/services/api';
import { userDarkModePreference, saveDarkModeUserPreference } from '@/lib/dark-mode';

/**
 * Private function to fetch user company.
 */
const getCompany = async (user: User): Promise<Company> => {
  const companyResponse: APIResponse = await $api.get(`/companies/${user.company.id}`);

  if (!companyResponse.data?.data) {
    throw new Error('could not get company data');
  }

  return companyResponse.data.data;
};

const setSessionTimeoutProperties = (logoutUrl: string): void => {
  $api.defaults.sessionTimeoutRedirect = logoutUrl;
  $api.defaults.sessionTimeoutDetection = true;
};

const store = createStore({
  modules: {

    user: {
      state: {
        user: null as null | User,
        darkMode: userDarkModePreference(),
        isLoading: false,
        fetchUserPromise: null,
        fetchCompanyPromise: null,
        flashData: null,
        language: 'en',
      },

      getters: {
        darkMode(state) {
          return state.darkMode;
        },

        isLoading(state): boolean {
          return state.isLoading;
        },

        themeClass(state): string {
          return state.darkMode ? 'is-dark-mode' : 'is-light-mode';
        },

        user(state): null | User {
          /** what if the current user isnt set?
           * when you get the user from a component (or vue app) THAT is where you check for user
           * it sucks I know, but getters do not have full store context
           */
          return state.user;
        },

        userLinks(): UserLink[] {
          return [
            {
              title: 'Update Profile',
              to: {
                name: 'profile',
              },
            },
            {
              title: 'Logout',
              to: {
                name: 'logout',
              },
            },
          ];
        },

        company(state) {
          return state.user?.company;
        },

        companyName(state): string | null {
          return state.user?.company?.name || null;
        },

        flashData(state): any {
          return state.flashData;
        },

        translatedLang(state): string {
          return state.language;
        },
      },

      mutations: {
        SET_LANG(state, payload: string): void {
          state.language = payload;
        },

        CLEAR_USER_STATE(state): void {
          state.user = null;
        },

        SET_IS_LOADING(state, payload: boolean): void {
          state.isLoading = payload;
        },

        SET_USER(state, payload): void {
          state.user = payload;
        },

        TOGGLE_DARK_MODE(state, payload: boolean): void {
          state.darkMode = payload;

          saveDarkModeUserPreference(payload);
        },

        SET_FLASH_DATA(state, payload: any): void {
          state.flashData = payload;
        },
      },

      actions: {
        /**
         * This is run on app initialization before any restricted paths are requested.
         */
        async fetchAuthUser({ commit, state }): Promise<boolean> {
          if (state.fetchUserPromise) {
            await state.fetchUserPromise;

            if (state.fetchCompanyPromise) {
              await state.fetchCompanyPromise;
            }

            return true;
          }

          try {
            commit('SET_IS_LOADING', true);

            state.fetchUserPromise = $api.get('/me');

            const response = await state.fetchUserPromise;
            const user = response.data.data;

            state.fetchCompanyPromise = getCompany(user);
            user.company = await state.fetchCompanyPromise;
            if (user.company.logout_url) {
              localStorage.setItem('logout_url', user.company.logout_url);
            }

            setSessionTimeoutProperties(user.company.logout_url);

            commit('SET_USER', user);
            return true;
          } catch (error) {
            // probably want to log the error here
            commit('SET_USER', null);
            throw error;
          } finally {
            commit('SET_IS_LOADING', false);
          }
        },

        async login({ commit }, payload): Promise<boolean> {
          const path = '/login';
          const creds: LoginRequestBody = {
            username: payload.username,
            password: payload.password,
          };

          if (payload.mfa_token) {
            creds.mfa_token = payload.mfa_token;
          }

          const urlParams = new URLSearchParams(window.location.search);

          if (payload.remember !== undefined) {
            creds.remember = payload.remember;
          }

          await $api.get(`${process.env.VUE_APP_API_URL}/api/csrf-cookie`);

          try {
            commit('SET_IS_LOADING', true);
            const userResponse: APIResponse = await $api.post(path, creds);

            if (!userResponse.data?.data?.user) {
              throw new Error('could not login');
            }

            const { user } = userResponse.data.data;

            if (user.role !== 'Student') {
              const params = urlParams.get('redirect') ?? '';
              window.location.href = decodeURIComponent(`${process.env.VUE_APP_ADMIN_URL}${params}`);
            }

            user.company = await getCompany(user);
            if (user.company.logout_url) {
              localStorage.setItem('logout_url', user.company.logout_url);
            }

            setSessionTimeoutProperties(user.company.logout_url);

            commit('SET_USER', user);

            return true;
          } finally {
            commit('SET_IS_LOADING', false);
          }
        },

        /**
         * Performs a login from an encrypted username token. Assumes the "default" password is set for the user.
         */
        async loginDefault({ commit }, token) {
          await $api.get(`${process.env.VUE_APP_API_URL}/api/csrf-cookie`);

          try {
            commit('SET_IS_LOADING', true);

            const userResponse = await $api.post('/login/default', { token });
            const { user } = userResponse.data.data;

            user.company = await getCompany(user);
            if (user.company.logout_url) {
              localStorage.setItem('logout_url', user.company.logout_url);
            }

            setSessionTimeoutProperties(user.company.logout_url);

            commit('SET_USER', user);

            return true;
          } finally {
            commit('SET_IS_LOADING', false);
          }
        },

        async logout({ commit }): Promise<string | null> {
          commit('SET_IS_LOADING', true);

          try {
            const response = await $api.post('/logout');

            commit('CLEAR_USER_STATE');

            $api.defaults.sessionTimeoutDetection = false;

            if (response.data?.logout_url?.length) {
              return response.data.logout_url;
            }

            return null;
          } finally {
            commit('SET_IS_LOADING', false);
          }
        },

        clearFlashData({ commit }, payload): void {
          commit('SET_FLASH_DATA', payload);
        },
      },
    },
  },
});

export default store;
