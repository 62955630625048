import { nextTick } from 'vue';
import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router';
import $store from '@/store/index';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TSplashScreenModal from '^@@/SplashScreen/SplashScreenModal';

const HeaderBar = () => import('../components/HeaderBar.vue');
const DEFAULT_TITLE = 'Traliant';

const defaultComponents = {
  HeaderBar,
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true,
      title: 'Home',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/Courses.vue'),
    },
  },
  {
    path: '/courses',
    name: 'courses',
    meta: {
      requiresAuth: true,
      title: 'Home',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/Courses.vue'),
    },
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true,
      title: 'Update Profile',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/UpdateProfile.vue'),
    },
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      lobby: true,
      title: 'Login',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/Login.vue'),
    },
  },
  {
    path: '/login/default',
    name: 'login-default',
    meta: {
      title: 'Login',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/LoginDefault.vue'),
    },
  },
  {
    path: '/logout',
    name: 'logout',
    components: {
      ...defaultComponents,
      default: () => import('../pages/Logout.vue'),
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      title: 'Reset Password',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/ResetPassword.vue'),
    },
  },
  {
    path: '/course/:id(\\d+)?',
    name: 'course-player',
    meta: {
      bypassIf: (to: {query: {token: string}}) => to.query.token,
      requiresAuth: true,
      coursePageHeader: true,
      title: 'Course Player',
      showSplash: false,
    },
    components: {
      HeaderBar: () => import('../components/PlayerHeaderBar.vue'),
      default: () => import('../pages/CoursePlayer.vue'),
    },
  },
  {
    path: '/course/error',
    name: 'course-error',
    meta: {
      title: 'Course Playback Error',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/CoursePlayerError.vue'),
    },
  },
  {
    path: '/login/error',
    name: 'login-error',
    meta: {
      title: 'Login Error',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/LoginError.vue'),
    },
  },
  {
    path: '/user/trial/registration',
    name: 'trial-registration',
    meta: {
      title: 'Trial User Registration',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/TrialUserRegistration.vue'),
    },
  },
  {
    path: '/campaigns/unsubscribe',
    name: 'campaigns-unsubscribe',
    meta: {
      title: 'Unsubscribe Campaign',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/CampaignsUnsubscribe.vue'),
    },
  },

  // this must be last so it catches 404s
  {
    path: '/access-denied',
    name: 'access-denied',
    meta: {
      title: 'Access denied',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/400View.vue'),
    },
  },

  // this must be last so it catches 404s
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../pages/404.vue'),
  },
];

// create the router object
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes,
});

const toggleBodyClass = (className: string, command: null | string) => {
  const $body = document.querySelector('body');
  if (!$body) { return; }

  if (command && ['add', 'remove'].includes(command)) {
    $body.classList[command](className);
  } else {
    $body.classList.toggle(className);
  }
};

router.beforeEach((to) => {
  if (to.name === 'login') {
    toggleBodyClass('has-blue-bg', 'add');
  }
});

router.afterEach((to) => {
  if (to.name === 'login') {
    toggleBodyClass('has-blue-bg', 'remove');
  }
});

/**
 * Check authenticated routes
 *
 * If the requested route requires authentication (requiresAuth meta field on the route)
 * and a user is not set then attempt to get the user via the v3/me endpoint
 *
 * If that works and the user is set, then proceed. Otherwise redirect to the login page.
 */
router.beforeEach((to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const loginPath = { path: '/login', query: { redirect: to.fullPath } };

  if (requiresAuth && typeof to.meta.bypassIf === 'function' && to.meta.bypassIf(to)) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initUser = (modal: any = null) => {
    $store.dispatch('fetchAuthUser')
      .then(() => {
        if (modal) {
          modal.close();
        }

        if (!$store.getters.user) {
          router.push(loginPath);
        }
      }).catch(() => {
        if (modal) {
          modal.close();
        }
        router.push(loginPath);
      });
  };

  if (requiresAuth && !$store.getters.user) {
    if (to.meta.showSplash !== false) {
      TSplashScreenModal({
        onAfterEnter: initUser, // This callback will pass in modal instance as parameter.
      });
    } else {
      initUser();
    }
  }
});

// set the page title
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to?.meta?.title ? `Traliant - ${to.meta.title}` : DEFAULT_TITLE;
  });
});

/**
 * Check if a user is in a lobby
 *
 * Lobbies are pages like the login in page - its typically where a user goes when
 * they are waiting to access a restricted area.
 */
router.beforeEach((to, from, next) => {
  // path: '/', query: { redirect: to.fullPath } };
  const lobby = to.matched.some((record) => record.meta.lobby);
  const rootPath = { path: '/', query: { redirect: to.fullPath } };

  if (lobby && !$store.getters.user) {
    $store.dispatch('fetchAuthUser')
      .then(() => {
        if (!$store.getters.user) {
          next();
        } else {
          next(rootPath);
        }
      })
      .catch(() => {
        next();
      });
  } else {
    next();
  }
});

export default router;
